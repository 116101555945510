import { Flex, Text, useDisclosure } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { CartContext, ShippingContext, UserContext } from '../../context'
import { CustomerHelper, ShippingUtils } from '../../utils'
import AddressSelection from './AddressSelection'
import ContactForm from './ContactForm'
import NewAddressForm from './NewAddressForm'
import ShippingModeItem from './ShippingModeItem'

const ExpressShippingMethod = (props) => {
  const {
    shippingMethod,
    validateShipping,
    isDisabled = false,
    isLoading = false,
    isAlreadySelected = false,
    promotion,
    calculateShippingPromotion
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentUser = useContext(UserContext);
  const currentCart = useContext(CartContext);
  const { shipping, setShipping } = useContext(ShippingContext);

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const onNewAdressFormClose = () => {
    const address = CustomerHelper.getDefaultBillingAddress(currentUser)
    updateShipping({ shippingCustomerAddress: address })
    onClose()
  }

  return (
    <ShippingModeItem
      isDisabled={isDisabled}
      title="Livraison Express"
      italicTitle="(paiement CB obligatoire)"
      subTitle="Expédié sous 48h"
      value="express"
      icon="shippingExpress"
      shippingMethod={shippingMethod}
      promotion={ promotion }
      calculateShippingPromotion={ calculateShippingPromotion }
      >
      {isOpen ? (
        <NewAddressForm onClose={onNewAdressFormClose} />
      ) : (
        <Flex direction="column" pl={{ md: '100px' }}>
          <Text
            as="h3"
            fontFamily="PT Sans, Arial, sans-serif"
            p={{ base: '0 0 20px 40px', md: '20px 0' }}
            fontWeight="600">
            Adresse de livraison
          </Text>
          <AddressSelection isDisabled={isAlreadySelected} showAddressForm={onOpen} />
          <ContactForm
            isLoading={isLoading}
            isValid={true}
            onClose={onClose}
            validateShipping={validateShipping}
          />
        </Flex>
      )}
    </ShippingModeItem>
  )
}
export default ExpressShippingMethod
