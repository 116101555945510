import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CircularProgress,
  Divider,
  Flex,
  Image,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { get, isEmpty, times, trim } from 'lodash'
import React, { useContext } from 'react'

import { CartContext, ShippingContext, WebsiteContext } from '../context'
import logoMR from '../shipping/mondialRelay/assets/mr-64.png'
import { priceFormat } from '../utils'
import CheckoutOrderDetails from './CheckoutOrderDetails'

const Address = ({ isHeavy = false, isAccordionItem = false }) => {
  const { shipping } = useContext(ShippingContext)
  const shippingMethod = get(shipping, `shippingMethod.${isHeavy ? 'heavy' : 'light'}`)
  let shippingAddress = get(shipping, `shippingAddress.${isHeavy ? 'heavy' : 'light'}`)
  const fullName = trim(
    `${shippingAddress?.customFields?.firstName || ''} ${
      shippingAddress?.customFields?.lastName || ''
    }`
  )

  const ItemWrapper = ({ children, isHeavy }) =>
    isAccordionItem ? (
      <AccordionItem w="100%">
        <AccordionButton px="0" w="100%" _focus={{ border: '0' }}>
          <Box
            flex="1"
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize="14px"
            color="#777"
            textAlign="left"
            pl="24px">
            {isHeavy ? 'Produit(s) en livraison standard' : 'Autre(s) produit(s)'}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p="0" w="100%" bg="#fff" border="1px solid #d4d4d4">
          {children}
        </AccordionPanel>
      </AccordionItem>
    ) : (
      <Flex w="100%">{children}</Flex>
    )

  const getShippingTypeText = () => {
    switch (shippingMethod.code) {
      case 'mondial-relay':
        return 'Retrait en point Relais Mondial Relay'
      case 'express':
        return 'Livraison Express à domicile'
      default:
        return 'Livraison à domicile'
    }
  }

  const getShippingPicto = () => {
    switch (shippingMethod?.code) {
      case 'mondial-relay':
        return (
          <Image
            src={logoMR}
            width="24px"
            height="24px"
            style={{ marginLeft: '10px', marginRight: '15px' }}
            alt="mondial relay"
          />
        )
      case 'express':
        return (
          <Picto
            icon="shippingExpress"
            width="52px"
            height="32px"
            color="#777"
            style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '-6px' }}
          />
        )
      default:
        return (
          <Picto
            icon="shipping"
            width="52px"
            height="32px"
            color="#777"
            style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '-6px' }}
          />
        )
    }
  }
  return (
    <ItemWrapper isHeavy={isHeavy}>
      <Flex py="20px" w="100%">
        {getShippingPicto()}
        <Box
          w="100%"
          fontFamily="PT Sans, Arial, sans-serif"
          color="#8B8B8B"
          fontSize="14px"
          lineHeight="18px">
          <Text maxW={shippingMethod.code === 'mondial-relay' ? '150px' : 'unset'} mb="10px">
            {getShippingTypeText()}
          </Text>
          {fullName !== '' && <Text>{fullName}</Text>}
          <Text>{shippingAddress?.streetLine1 || ''}</Text>
          <Text>{shippingAddress?.streetLine2 || ''}</Text>
          <Text>{`${shippingAddress?.postalCode || ''} ${shippingAddress?.city || ''}`}</Text>
        </Box>
      </Flex>
    </ItemWrapper>
  )
}

const Addresses = () => {
  const { shipping } = useContext(ShippingContext)
  const isMultiple =
    shipping?.showLightShipping &&
    shipping?.showHeavyShipping &&
    shipping?.shippingMethod?.light &&
    shipping?.shippingMethod?.heavy
  const Wrapper = ({ children }) =>
    isMultiple ? (
      <Accordion allowMultiple defaultIndex={times(Object.keys(shipping || {}).length)}>
        {children}
      </Accordion>
    ) : (
      <Flex borderBottom="1px solid #d4d4d4">{children}</Flex>
    )

  return (
    <Flex direction="column" w="100%">
      <Wrapper>
        {shipping?.showHeavyShipping && shipping?.shippingMethod?.heavy && (
          <Address isHeavy={true} isAccordionItem={isMultiple} />
        )}
        {shipping?.showLightShipping && shipping?.shippingMethod?.light && (
          <Address isHeavy={false} isAccordionItem={isMultiple} />
        )}
      </Wrapper>
    </Flex>
  )
}

const CheckoutShippingSummary = ({ promotions, calculateShippingPromotion }) => {
  const websiteContext = useContext(WebsiteContext);
  const currentCart = useContext(CartContext);
  const { shipping } = useContext(ShippingContext);

  return (
    <Flex w={{ base: '100%', md: '430px' }} flexDirection="column">
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem style={{ borderBottomWidth: '0' }}>
          <AccordionButton p="0" style={{ boxShadow: 'none' }}>
            <Flex
              backgroundColor={websiteContext.mainColor}
              p="10px 20px"
              w="100%"
              align="center"
              justify="space-between">
              <Flex color="white" align="center">
                <Text
                  as="span"
                  fontWeight="bold"
                  fontSize="22px"
                  mr="5px"
                  fontFamily="PT Sans, Arial, Sans-serif">
                  Récapitulatif
                </Text>
                <Text as="span" fontFamily="PT Sans, Arial, Sans-serif">
                  {` (${currentCart?.customFields?.totalProductQuantity} produit${
                    currentCart?.customFields?.totalProductQuantity > 1 ? 's' : ''
                  })`}
                </Text>
              </Flex>
              <AccordionIcon color="#FFFF" width="32px" height="32px" display={['flex', 'none']} />
            </Flex>
          </AccordionButton>
          <AccordionPanel p="20px" bg="#f7f7f7">
            <Box>
              <Flex pb="10px" justifyContent="space-between" align="center">
                <Text fontFamily="PT Sans" fontSize="16px">
                  Total commandé
                </Text>
                <Text fontWeight="bold" fontSize="22px">
                  {priceFormat(currentCart ? currentCart.subTotalWithTax : '0', 'EUR')}
                </Text>
              </Flex>
              <Divider borderColor="#d4d4d4" />
              <CheckoutOrderDetails
                details={currentCart?.lines
                  ?.filter((ol) =>
                    ['FID', 'PDT'].includes(ol?.productVariant?.product?.customFields?.productType)
                  )
                  ?.sort((a, b) => {
                    if (a?.productVariant?.product?.customFields?.productType === 'FID') {
                      return 1
                    }
                    if (b?.productVariant?.product?.customFields?.productType === 'FID') {
                      return -1
                    }
                    return 0
                  })
                  ?.map((ol) => ({
                    title: ol.productVariant?.name,
                    price: ol?.linePriceWithTax
                  }))}
              />
              {!isEmpty(currentCart?.discounts) || currentCart?.customFields?.totalWarrantyPrice ? (
                <>
                  <CheckoutOrderDetails
                    details={[
                      ...(currentCart?.discounts || []).map((d) => ({
                        title: 'Code promo',
                        price: d.amountWithTax
                      })),
                      ...[{
                        title: 'Extension de garantie',
                        price: currentCart?.customFields?.totalWarrantyPrice || 0
                      }].filter(f => f.price > 0)
                    ]}
                  />
                </>
              ) : null}
              <Flex justifyContent="space-between" flexDirection="column" py="10px">
                <Flex align="center" justifyContent="space-between">
                <Text as="u" fontFamily="PT Sans, Arial, sans-serif">
                  Frais de livraison
                </Text>
                {shipping?.isLoading ? (
                  <Flex align="center">
                    <CircularProgress
                      size="36px"
                      isIndeterminate
                      color={websiteContext?.mainColor}
                    />
                  </Flex>
                ) : (
                  <Text fontWeight="bold" fontSize="22px">
                    {priceFormat(
                      calculateShippingPromotion(shipping?.shippingMethod?.heavy, promotions, (shipping?.shippingPrice?.heavy || 0), true, false, true) + 
                      calculateShippingPromotion(shipping?.shippingMethod?.light, promotions, (shipping?.shippingPrice?.light || 0), true, false, false),
                      'EUR',
                      'Offert'
                    )}
                  </Text>
                )}
                </Flex>
              </Flex>
              <Divider borderColor="#d4d4d4" />
              <Addresses />
              {/* <Divider borderColor="#d4d4d4" /> */}
              <Flex pt="20px" justifyContent="space-between">
                <Text alignSelf="center" fontFamily="PT Sans, Arial, sans-serif">
                  Total
                </Text>
                <Text fontWeight="bold" fontSize="30px">
                  {priceFormat(
                    (currentCart?.subTotalWithTax || 0) +
                    calculateShippingPromotion(shipping?.shippingMethod?.heavy, promotions, (shipping?.shippingPrice?.heavy || 0), true, false, true) +
                    calculateShippingPromotion(shipping?.shippingMethod?.light, promotions, (shipping?.shippingPrice?.light || 0), true, false, false),
                    'EUR'
                  )}
                </Text>
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default CheckoutShippingSummary
