import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Picto,
  Radio,
  Text
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { useContext } from 'react'

import { CartContext, ShippingContext, UserContext, WebsiteContext } from '../../context'
import logoMR from '../../shipping/mondialRelay/assets/mr-64.png'
import { priceFormat, ShippingUtils } from '../../utils'
import OrderItemsShippingDetail from '../OrderItemsShippingDetail'

const ShippingModeItem = (props) => {
  const {
    title,
    italicTitle,
    subTitle,
    children,
    icon = 'shipping',
    shippingMethod: _shippingMethod,
    isHeavy = false,
    promotion,
    calculateShippingPromotion
  } = props;
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const { shipping, setShipping } = useContext(ShippingContext)
  const websiteContext = useContext(WebsiteContext)
  const selected =
    get(shipping, `shippingMethod.${isHeavy ? 'heavy' : 'light'}.code`) === _shippingMethod?.code

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const handleSelect = () => {
    if (_shippingMethod) {
      updateShipping({ shippingMethod: _shippingMethod, isHeavy: isHeavy })
    }
  }

  const getShippingPrice = () => {
    if (_shippingMethod?.priceWithTax === 0) {
      return 0
    }
    if (_shippingMethod?.priceWithTax === _shippingMethod?.customFields?.applicationFee) {
      return _shippingMethod?.customFields?.applicationFee
    }
    const applicationFee = _shippingMethod?.customFields?.applicationFee || 0
    let result = 0
    currentCart?.lines
      ?.filter((ol) => !!ol?.productVariant?.product?.customFields?.isHeavyProduct === isHeavy)
      .forEach((ol) => {
        result = result + ol?.customFields?.totalShippingFee
      })
    return result + applicationFee
  }

  const getApplicationFeePrice = () => priceFormat( calculateShippingPromotion(_shippingMethod, promotion, _shippingMethod?.customFields?.applicationFee || 0, false, true, false), 'EUR', 'Offert');
  return (
    <>
      <AccordionItem
        borderWidth="1px"
        borderStyle="solid"
        borderColor={selected ? websiteContext?.mainColor : '#ced4e1'}
        mb="10px">
        <AccordionButton p="15px" onClick={handleSelect} _focus={{ border: 'none' }}>
          <Box flex="1" textAlign="left">
            <Radio
              color={websiteContext?.mainColor}
              isChecked={selected}
              py="10px"
              isFullWidth={true}
              id={`shipping_${_shippingMethod?.code}`}>
              <Flex w="100%" justifyContent="space-between">
                <Flex>
                  <Box alignSelf="center" w="52px" display={['none', 'block']} m="0 15px 0 5px">
                    {_shippingMethod?.code === 'mondial-relay' ? (
                      <Image src={logoMR} width="32px" height="32px" m="auto" alt="mondial relay" />
                    ) : (
                      <Picto
                        icon={icon}
                        width={_shippingMethod?.code === 'standard' ? '52px' : '54px'}
                        color="#777"
                      />
                    )}
                  </Box>
                  <Box ml={{ base: '10px', md: '0' }} maxW={{ base: '180px', md: 'unset' }}>
                    <Text fontSize="16px" style={{ fontWeight: 600, lineHeight: '18px' }}>
                      {title}
                    </Text>
                    {italicTitle && <Text as="i">{italicTitle}</Text>}
                    <Text fontSize="16px">{subTitle}</Text>
                  </Box>
                </Flex>
              </Flex>
            </Radio>
          </Box>
          <Text style={{ fontWeight: 600, lineHeight: '12px' }} p={{ md: '10px 15px' }}>
            {priceFormat(calculateShippingPromotion(_shippingMethod, promotion, getShippingPrice(), true, false, isHeavy), 'EUR', 'Offert')}
          </Text>
        </AccordionButton>
        <AccordionPanel>
          <Box>{children}</Box>
          <Box pl={['0', '100px']} pb="20px">
            <OrderItemsShippingDetail isHeavy={isHeavy} calculateShippingPromotion={(price, heavy) => calculateShippingPromotion(_shippingMethod, promotion, price, false, false, heavy)} />
            {_shippingMethod?.customFields?.applicationFee !== 0 && (
              <Flex
                justifyContent="space-between"
                width="auto"
                borderTop="1px solid #d4d4d4"
                mr={{ base: '0', md: '77px' }}
                pl={{ base: '85px', md: '100px' }}
                align="center">
                <Flex w="100%" p="20px 5px 20px 15px" justifyContent="space-between" align="center">
                  <Box w="70%" pr="5px">
                    <Text
                      fontSize="14px"
                      fontFamily="PT Sans, Arial, Sans-serif">{`Frais de ${title}`}</Text>
                  </Box>
                  <Text
                    fontSize="14px" fontFamily="PT Sans, Arial, Sans-serif"
                    fontWeight={getApplicationFeePrice() == 'Offert' ? 'bold' : 'normal'}
                    textTransform={getApplicationFeePrice() == 'Offert' ? 'uppercase' : 'initial'}>
                    {getApplicationFeePrice()}
                  </Text>
                </Flex>
              </Flex>
            )}
          </Box>
        </AccordionPanel>
      </AccordionItem>
    </>
  )
}

export default ShippingModeItem
