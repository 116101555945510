import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  useState
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { useContext, useEffect } from 'react'

import {
  ApiContext,
  CartContext,
  ShippingContext,
  UserContext,
  WebsiteContext
} from '../../context'
import { MondialRelay } from '../../shipping/mondialRelay'
import { CustomerHelper, ShippingUtils } from '../../utils'
import ContactForm from './ContactForm'
import ShippingModeItem from './ShippingModeItem'

const MondialRelayShippingMethod = (props) => {
  const { 
    validateShipping,
    shippingMethod,
    isDisabled = false,
    isLoading = false,
    promotion,
    calculateShippingPromotion
  } = props;
  const currentUser = useContext(UserContext)
  const currentCart = useContext(CartContext)
  const { shipping, setShipping } = useContext(ShippingContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const apiContext = useContext(ApiContext)
  const [selectParcelModal, setSelectParcelModal] = useState()
  const [selectParcel, setSelectParcel] = useState()
  const [address, setAddress] = useState({})
  const websiteContext = useContext(WebsiteContext)

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  useEffect(() => {
    const address = CustomerHelper.getDefaultBillingAddress(currentUser)
    setAddress(address)
  }, [currentUser])

  useEffect(() => {
    updateShipping({
      shippingRelayAddress: {
        type: selectParcel?.type,
        id: selectParcel?.id,
        streetLine1: selectParcel?.name,
        streetLine2: selectParcel?.address1,
        city: selectParcel?.city,
        postalCode: selectParcel?.postalCode,
        schedule: selectParcel?.schedule
      }
    })
  }, [selectParcel])

  return (
    <ShippingModeItem
      isDisabled={isDisabled}
      title="Retrait en point Relais Mondial Relay"
      subTitle="Expédié entre 3 et 5 jours"
      value="mondial-relay"
      shippingMethod={shippingMethod}
      promotion={promotion}
      calculateShippingPromotion={calculateShippingPromotion}
      >
      <>
        {get(shipping, 'shippingAddress.light.type') && get(shipping, 'shippingAddress.light.id') && (
          <Flex direction="column" pl={{ base: '40px', md: '100px' }}>
            <Text>{get(shipping, 'shippingAddress.light.streetLine1', '')}</Text>
            <Text> {get(shipping, 'shippingAddress.light.streetLine2', '')}</Text>
            <Text>{`${get(shipping, 'shippingAddress.light.postalCode', '')} ${get(
              shipping,
              'shippingAddress.light.city',
              ''
            )}`}</Text>
          </Flex>
        )}
        <Flex direction="column" pl={{ md: '100px' }}>
          <Button
            onClick={onOpen}
            variant="unstyled"
            textAlign="left"
            textDecor="underline"
            fontWeight="normal"
            fontFamily="PT Sans, Arial, sans-serif"
            color="#333333"
            fontSize={{ base: '18px', md: '16px' }}
            m={{ base: '0 10px 0 40px', md: '0' }}
            _hover={{ boxShadow: 'none' }}
            _focus={{ boxShadow: 'none' }}>
            {get(shipping, 'shippingAddress.light.type') &&
            get(shipping, 'shippingAddress.light.id')
              ? 'Changer de point Relais'
              : 'Choisir un point Relais'}
          </Button>
          <Text
            as="h3"
            fontFamily="PT Sans, Arial, sans-serif"
            pt="20px"
            fontWeight="600"
            display={{ base: 'none', md: 'block' }}>
            Contact de livraison
          </Text>
          <ContactForm
            isLoading={isLoading}
            validateShipping={validateShipping}
            isValid={
              get(shipping, 'shippingAddress.light.type') &&
              get(shipping, 'shippingAddress.light.id')
            }
          />
        </Flex>
      </>
      <Modal onClose={onClose} isOpen={isOpen} isCentered size={'2xl'} p="0">
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <ModalCloseButton style={{ right: '-4px', boxShadow: 'none' }} />
          <ModalBody p="0">
            <MondialRelay
              fetch={apiContext?.ExternalServicesApi?.getExternalData}
              brand={shippingMethod?.customFields?.brand || 'F2TELESP'}
              maxResults={shippingMethod?.customFields?.nbResults}
              defaultPostCode={address?.postalCode}
              setSelectParcel={setSelectParcelModal}
            />
          </ModalBody>
          <ModalFooter px="0" pb="10px" pt="20px">
            <Button
              backgroundColor={websiteContext.mainColor}
              _hover={{ bg: websiteContext.darkColor }}
              color="white"
              fontSize="16px"
              fontFamily="PT Sans Narrow"
              fontWeight="0"
              borderRadius="0px"
              size={'sm'}
              mr={3}
              disabled={!selectParcelModal}
              onClick={() => {
                setSelectParcel(selectParcelModal)
                onClose()
              }}>
              Choisir ce relais
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ShippingModeItem>
  )
}

export default MondialRelayShippingMethod
