import { Box, Flex, Image, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { CartContext } from '../context'
import { priceFormat } from '../utils'

const OrderItemsShippingDetail = (props) => {
  const { isHeavy, calculateShippingPromotion } = props;
  const currentCart = useContext(CartContext);

  return (
    <>
      <Text as="h3" fontFamily="PT Sans, Arial, sans-serif" pt="20px" fontWeight="600">
        Détail des frais de livraison :
      </Text>
      <Flex
        flexDirection="column"
        fontFamily="PT Sans, Arial, sans-serif"
        mt="5px"
        mr={{ base: '0', md: '77px' }}>
        {currentCart?.lines
          ?.filter(
            (ol) =>
              !!ol?.productVariant?.product?.customFields?.isHeavyProduct === !!isHeavy &&
              ['FID', 'PDT'].includes(ol?.productVariant?.product?.customFields?.productType)
          )
          ?.map((orderLine, i) => {
            const itemShippingFee = priceFormat(
              calculateShippingPromotion(orderLine?.customFields?.totalShippingFee, isHeavy),
              'EUR',
              'Offert'
            )
            return (
              <Flex
                width="100%"
                key={i}
                my="5px"
                borderTop={i !== 0 ? '1px solid #d4d4d4' : 'none'}
                order={
                  orderLine?.productVariant?.product?.customFields?.productType === 'FID'
                    ? currentCart?.customFields?.totalProductQuantity
                    : 0
                }>
                <Flex justifyContent="space-between" width="100%" align="center" p="3px 0">
                  <Image
                    objectFit="cover"
                    w={{ base: '85px', md: '100px' }}
                    h="auto"
                    src={orderLine.featuredAsset?.customFields?.sourceOrigin}
                  />
                  <Flex
                    w="100%"
                    p="15px 5px 10px 15px"
                    justifyContent="space-between"
                    align="center">
                    <Box w="70%" pr="5px">
                      <Text
                        fontSize="14px"
                        overflow="hidden"
                        style={{
                          textOverflow: 'ellipsis',
                          WebkitLineClamp: 2,
                          display: '-webkit-box',
                          WebkitBoxOrient: 'vertical'
                        }}>
                        {orderLine?.productVariant?.name}
                      </Text>
                      <Text fontSize="14px">Quantité : {orderLine.quantity}</Text>
                    </Box>
                    <Text
                      fontSize="14px"
                      fontWeight={itemShippingFee == 'Offert' ? 'bold' : 'normal'}
                      textTransform={itemShippingFee == 'Offert' ? 'uppercase' : 'initial'}>
                      {itemShippingFee}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )
          })}
      </Flex>
    </>
  )
}

export default OrderItemsShippingDetail
