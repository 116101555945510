import { Accordion, Box, CircularProgress, Text, useState } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import { navigate } from 'gatsby'
import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import set from 'lodash/set'
import React, { memo, useContext, useEffect } from 'react'

import { ApiContext, CartContext, DataLayerUtils, ShippingContext, UserContext } from '../context'
import { CustomerHelper, ShippingUtils } from '../utils'
import {
  ExpressShippingMethod,
  MondialRelayShippingMethod,
  StandardShippingMethod
} from './shipping'

const ShippingModeList = ({promotions, calculateShippingPromotion}) => {
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const { shipping, setShipping } = useContext(ShippingContext)
  const [loading, setLoading] = useState(false)
  const [validateLoading, setValidateLoading] = useState(false)
  const [step, setStep] = useState(1)
  const [eligibleShippingMethods, setEligibleShippingMethods] = useState([])
  const websiteContext = useContext(WebsiteContext)

  const updateShipping = ({
    step,
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false,
    isLoading = true,
    eligibleShippingMethods
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        step,
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy,
        isLoading,
        eligibleShippingMethods
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const getEligibleShippingMethod = async () => {
    setLoading(true)
    const result = await apiContext?.OrderApi?.eligibleShippingMethods()
    updateShipping({ step, eligibleShippingMethods: result, isLoading: false })
    setEligibleShippingMethods(result || [])
    setLoading(false)
  }

  useEffect(() => {
    getEligibleShippingMethod()
  }, [currentCart?.id])

  const validateFinalShipping = async () => {
    setValidateLoading(true)
    const params = {}
    const heavyShippingAddress = shipping?.shippingAddress?.heavy
    const lightShippingAddress = shipping?.shippingAddress?.light
    const heavyShippingMethod = shipping?.shippingMethod?.heavy
    const lightShippingMethod = shipping?.shippingMethod?.light
    const billingAddress = CustomerHelper.getDefaultBillingAddress(currentUser)
    const defaultShippingAddress = CustomerHelper.getDefaultShippingAddress(currentUser)
    const windowGlobal = typeof window !== 'undefined' && window

    set(params, 'shippingMethod', lightShippingMethod || heavyShippingMethod)
    if (billingAddress) {
      set(params, 'billingAddress', billingAddress)
    }

    set(params, 'shippingAddress', heavyShippingAddress || lightShippingAddress)
    set(params, 'phone', shipping?.phone)
    set(params, 'cellPhone', shipping?.cellPhone)

    if (lightShippingAddress?.type) {
      set(params, 'relay', {
        relayType: lightShippingAddress?.type,
        relayCode: lightShippingAddress?.id,
        relayName: lightShippingAddress?.streetLine1,
        relayAddress: lightShippingAddress?.streetLine2,
        relayPostalCode: lightShippingAddress?.postalCode,
        relayCity: lightShippingAddress?.city,
        relaySchedule: lightShippingAddress?.schedule
      })
    }
    if (!params.shippingAddress || params.shippingAddress.type) {
      set(params, 'shippingAddress', defaultShippingAddress)
    }

    await apiContext?.OrderApi?.setShipping(params)

    DataLayerUtils.addShippingEvent({
      order: currentCart,
      command: 'checkoutStep',
      customer: currentUser,
      checkoutStepNumber: 4,
      checkoutStepName: 'Paiement'
    })
    navigate(`/checkout/payment?previousUrl=shipping`, {
      replace: true
    })
  }

  const validate = () => {
    if (shipping?.hasHeavyProduct && shipping?.hasLightProduct && step === 1) {
      setStep(2)
      updateShipping({ step: 2 })
    } else {
      updateShipping({ step })
      validateFinalShipping()
    }
  }

  return (
    <Box w="100%" mt={{ base: '24px', md: '0' }}>
      <Text
        as="h1"
        p={{ md: '11px 0' }}
        fontSize={{ base: '18px', md: '22px' }}
        style={{
          color: 'rgb(36,42,48)',
          fontWeight: 700,
          fontFamily: 'PT Sans, Arial, sans-serif'
        }}>
        Choisissez votre mode de livraison :
      </Text>
      {isEmpty(eligibleShippingMethods) ? (
        <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
      ) : (
        <>
          {shipping?.hasHeavyProduct && shipping?.hasLightProduct && (
            <Text
              as="h3"
              mt={{ base: '18px', md: '0' }}
              fontFamily="PT Sans, Arial, sans-serif"
              style={{
                fontSize: '18px',
                color: 'rgb(36,42,48)',
                fontWeight: 700
              }}>
              Étape {step === 1 ? '1' : '2'}/2 :{' '}
              <Text as="span" fontWeight="normal">
                {step === 1 ? 'Produit(s) en livraison standard' : 'Autre(s) produit(s)'}
              </Text>
            </Text>
          )}

          <DisplayShippingMethods
            eligibleShippingMethods={eligibleShippingMethods}
            shipping={shipping}
            loading={loading}
            validateLoading={validateLoading}
            validate={validate}
            step={step}
            promotions={ promotions }
            calculateShippingPromotion={ calculateShippingPromotion }
          />
        </>
      )}
    </Box>
  )
}

const _DisplayShippingMethods = ({
  eligibleShippingMethods = [],
  shipping = {},
  loading = false,
  validateLoading = false,
  validate = () => {},
  step = 1,
  promotions,
  calculateShippingPromotion
}) => {
  const [mondialRelaySM, setMondialRelaySM] = useState({});
  const [standardSM, setStandardSM] = useState({});
  const [expressSM, setExpressSM] = useState({});
  const [indexer, setIndexer] = useState([]);
  const [selected, setSelected] = useState(0);
  const websiteContext = useContext(WebsiteContext);

  const validateShipping = async () => validate();

  const getIndex = (indexer) => {
    const code = get(shipping, 'shippingMethod.light.code')
    const idx = findIndex(indexer, (item) => item === code) || 0
    return idx <= 0 ? 0 : idx
  }

  useEffect(() => {
    setIndexer([])
    if (!isEmpty(eligibleShippingMethods) && !isEmpty(shipping)) {
      const _idx = []
      const _mondialRelay = eligibleShippingMethods?.find((sm) => sm.code === 'mondial-relay')
      setMondialRelaySM(_mondialRelay)
      if (_mondialRelay) {
        _idx.push(_mondialRelay.code)
      }

      const _standard = eligibleShippingMethods?.find((sm) => sm.code === 'standard')
      setStandardSM(_standard)
      if (_standard) {
        _idx.push(_standard.code)
      }

      const _express = eligibleShippingMethods?.find((sm) => sm.code === 'express')
      setExpressSM(_express)
      if (_express) {
        _idx.push(_express.code)
      }
      setIndexer(_idx)
    }
  }, [eligibleShippingMethods, shipping])

  useEffect(() => {
    setSelected(getIndex(indexer))
  }, [indexer, step])

  return (
    <>
      {loading ? (
        <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
      ) : (
        <>
          {shipping?.hasHeavyProduct && step === 1 && standardSM ? (
            <Accordion my="20px" mr={{ base: 0, md: '20px' }} defaultIndex={[0]}>
              <StandardShippingMethod
                isLoading={validateLoading}
                isDisabled={loading && validateLoading}
                key="standard"
                shippingMethod={standardSM}
                isAlreadySelected={false}
                validateShipping={validateShipping}
                isHeavy={true}
                promotion={ promotions }
                calculateShippingPromotion={ calculateShippingPromotion }
              />
            </Accordion>
          ) : (
            <Accordion
              my="20px"
              mr={{ base: 0, md: '20px' }}
              index={[selected]}
              // defaultIndex={[selected]}
            >
              {mondialRelaySM && (
                <MondialRelayShippingMethod
                  isLoading={validateLoading}
                  isDisabled={loading && validateLoading}
                  key="mondial-relay"
                  shippingMethod={mondialRelaySM}
                  validateShipping={validateShipping}
                  promotion={ promotions }
                  calculateShippingPromotion={ calculateShippingPromotion }
                />
              )}
              {standardSM && (
                <StandardShippingMethod
                  isLoading={validateLoading}
                  isDisabled={loading && validateLoading}
                  key="standard"
                  isAlreadySelected={
                    shipping?.hasHeavyProduct && shipping?.hasLightProduct && step > 1
                  }
                  shippingMethod={standardSM}
                  validateShipping={validateShipping}
                  promotion={ promotions }
                  calculateShippingPromotion={ calculateShippingPromotion }
                />
              )}
              {expressSM && (
                <ExpressShippingMethod
                  isLoading={validateLoading}
                  isDisabled={loading && validateLoading}
                  key="express"
                  isAlreadySelected={shipping?.hasHeavyProduct && step === 2}
                  shippingMethod={expressSM}
                  validateShipping={validateShipping}
                  promotion={ promotions }
                  calculateShippingPromotion={ calculateShippingPromotion }
                />
              )}
            </Accordion>
          )}
        </>
      )}
    </>
  )
}

const DisplayShippingMethods = memo(_DisplayShippingMethods)

export default ShippingModeList
