import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React from 'react'
import { priceFormat } from '../utils'

const CheckoutOrderDetails = (props) => {
  const { details } = props
  return (
    <Accordion allowMultiple>
      {details
        ?.map((item, i) => (
          <AccordionItem key={`item-${i}`} borderTop="0" borderBottom="1px solid #d4d4d4">
            <AccordionButton pl="0" pr="4px" _focus={{ border: '0' }}>
              <Box
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#777777"
                flex="1"
                textAlign="left"
                pl="24px"
                overflow="hidden"
                style={{
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical'
                }}>
                {item.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#777777"
                width="100%"
                textAlign="right">
                {priceFormat(item.price, 'EUR', "Offert")}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  )
}

export default CheckoutOrderDetails;